async function handleFormSubmission(event) {
    event.preventDefault();
    const form = new FormData(event.target);

    const data = {
        sku: form.get('sku'),
        name: form.get('name'),
    };

    const response = await fetch('/.netlify/functions/create-checkout', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    }).then((res) => res.json());

    const stripe = Stripe(response.publishableKey);
    const {
        error
    } = await stripe.redirectToCheckout({
        sessionId: response.sessionId,
    });

    if (error) {
        console.error(error);
    }
}

function setup() {
    const products = document.querySelectorAll(`[data-checkout="items"] form`);
    if (products && products.length > 0) {
        Array.from(products)
            .map(form => {
                form.addEventListener('submit', handleFormSubmission);
            })
    }
}

export default setup;