import workshopInstanceSetup from './modules/workshopInstances';
import checkoutButtonSetup from './modules/checkoutButton';

workshopInstanceSetup();
checkoutButtonSetup();

const links = document.querySelectorAll('a[href^="http"]');
if (links && links.length > 0) {
    Array.from(links)
       .filter(el => {
            const url = new URL(el.href);
            return url.host !== document.location.host;
        }).map(el => {
            const target = el.target;
            const rel = el.rel;
            if (!target) {
                el.target = `_blank`;
            }
            if ((!target && !rel) || (target === `_blank` && !rel)) {
                el.rel = `noopener noreferrer`;
            }
        });
}