import DateTime from 'luxon/src/datetime';

function setup() {
    /**
     * This handles the workshop previews
     */
    const workshopInstances = document.querySelectorAll(`[data-workshop-instances]`);
    if (workshopInstances && workshopInstances.length > 0) {
        let counter = 0;
        let instances = Array.from(workshopInstances).map(el => {
            let next = false;
            const header = el.querySelector(`header`)
            if (el.dataset.workshopInstances && el.dataset.workshopInstances.length > 2) {
                const instances = JSON.parse(el.dataset.workshopInstances)
                    .map(instance => {
                        // Forestry saves times w/ a "Z" which forces Luxon to parse them as UTC, which
                        // will sometimes result in the wrong date.
                        instance.date = DateTime.fromISO(instance.start, { zone: 'America/Los_Angeles' });
                        return instance;
                    })
                    .sort((a, b) => a.date > b.date);
                next = instances
                    .find(instance => instance.date.diffNow() > 0)
                counter++;
            }
            if (next) {
                header.appendChild(document.createRange()
                    .createContextualFragment(`<datetime class="uppercase mt-3 tracking-wider text-xs inline-block py-1 border-b-4 border-core-primary">Next Workshop: <span class="block md:inline">${next.date.toLocaleString(DateTime.DATE_FULL)}</span></datetime>`));
                    el.style.order = counter;
                    el.order = counter;
            } else {
                header.appendChild(document.createRange()
                    .createContextualFragment(`<span class="uppercase mt-3 tracking-wider text-xs inline-block py-1 border-b-4 border-core-secondary text-core-primary font-bold"><a href="/contact/">Contact Us for more info</a></span>`));
                    el.style.order = counter + workshopInstances.length;
                    el.order = counter + workshopInstances.length;
            }
            return el
        })
        .sort((a, b) => a.order > b.order)

        const first = instances.shift();
        // Add first-item styles
        first.classList.add('md:col-span-2', 'border-core-primary')

        // Modify image to be wider
        const firstImage = first.querySelector('img');
        if (firstImage) {
            firstImage.src = firstImage.src.replace('w_400', 'w_800')
        }
    }

    /**
     * This handles the instances on single workshops.
     */
    const singleInstances = document.querySelector(`.workshop-instances`);
    if (singleInstances) {
        const instanceTimes = singleInstances.querySelectorAll(`time`);
        let counter = 1;
        const next = Array.from(instanceTimes)
            .sort((a, b) => a.dateTime > b.dateTime)
            .map(time => {
                time.order = counter;
                counter++;
                return time;
            })
            .map(time => {
                const listWrapper = time.parentElement;
                time.inPast = DateTime.fromISO(time.dateTime).diffNow() < 0;
                listWrapper.style.order = time.inPast ? time.order + instanceTimes.length : time.order;
                listWrapper.classList.add(time.inPast ? `past` : `upcoming`);
                return time;
            })
            .filter(time => !time.inPast)
            .reduce((acc, cur) => {
                if (null === acc) {
                    return cur; // first item
                }
                return cur.order < acc.order ? cur : acc;
            }, null);
        if (next) {
            next.parentElement.classList.add(`next`);
        }
    }
}

export default setup;
